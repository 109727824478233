/* Define the keyframes for the scaling animation */
@keyframes wave {
    0% {
        transform: rotate(0deg) scale(1);
    }
    15% {
        transform: rotate(-10deg) scale(1.05);
    }
    30% {
        transform: rotate(0deg) scale(1);
    }
}

.animation-wave {
    animation: wave 2s infinite;
    position: relative;
    display: inline-block;
}