ul, ol {
  padding: 0 0 0 20px;
}

ul {
  list-style-type: circle;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list li {
  margin-bottom: 5px;
}

.listitem {
  display: flex;
}

.listitem__body > * {
  display: block;
  margin-bottom: 10px;
}

@media all and (max-width: 600px) {
  .listitem {
    display: block;
  }
}
