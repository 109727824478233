/* Import font */
@font-face {
    font-family: 'American Typewriter';
    src: url('/assets/fonts/american-typewriter.ttf') format('truetype');
}

@font-face {
    font-family: 'American Typewriter';
    font-weight: bold;
    src: url('/assets/fonts/american-typewriter-bold.ttf') format('truetype');
}

/* Font sizes */
:root {
    --font-size-p: 1.8rem;
    --font-size-h5: 1.5rem;
    --font-size-h4: 1.8rem;
    --font-size-h3: 2.0rem;
    --font-size-h2: 2.8rem;
    --font-size-h1: 3.6rem;
    --font-family-copy: 'American Typewriter';
    --font-family-headline: 'American Typewriter';
    --font-family-logo: 'American Typewriter';
}

html {
    font-size: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

body {
    font-size: var(--font-size-p);
    font-family: var(--font-family-copy);
}

h1, h2, h3, h4, h5 {
    margin: 0 0 .5rem;
    font-weight: 600;
}

h1 {
    font-family: var(--font-family-headline);
    font-size: var(--font-size-h1);
}

h2 {
    font-family: var(--font-family-headline);
    font-size: var(--font-size-h2);
}

h3 {
    font-family: var(--font-family-headline);
    font-size: var(--font-size-h3);
}

p, ul, ol {
    font-family: var(--font-family-copy);
    font-size: var(--font-size-p);
    line-height: 1.4;
    &:first-child {
        margin-top: 0;
    }
}

a {
    color: inherit;
    text-decoration: none;
}

main a {
    text-decoration: underline;
    text-decoration-color: red;
}

a:hover {
    color: red;
    text-decoration: none;
}

summary {
    cursor: pointer;
}

blockquote {
    margin: 0;
    padding: 8px 16px;
    border-left: solid 2px black;
    font-family: var(--font-family-headline);
}

.font-copy {
    font-family: var(--font-family-copy);
}

.font-headline {
    font-family: var(--font-family-headline);
}

.font-smallcaps {
    font-weight: 500;
    font-family: var(--font-family-headline);
    text-transform: uppercase;
    font-size: 0.8em;
}

hr {
    border-color: black;
}

b, strong {
    font-weight: 600;
}