.grid {
  display: grid;
  grid-template-columns: auto 660px auto;
  grid-template-areas: 
    "name nav follow"
    ". body ."
    "copyright . archive";
    height: calc(var(--vh, 1vh) * 100);
}

.grid__top-left {
  grid-area: name;
  padding: 12px 0 0 12px;
  font-weight: 500;
}

.grid__top-center {
  grid-area: nav;
  padding: 12px 0 0;
}

.grid__body {
  grid-area: body;
  padding: 60px 0;
}

.grid__top-right {
  grid-area: follow;
  padding: 12px 12px 0 0;
}

.grid__bottom-left {
  grid-area: copyright;
  align-self: end;
  padding: 0 0 12px 12px;
}

.grid__bottom-right {
  grid-area: archive;
  align-self: end;
  padding: 0 12px 12px 0;
}

.grid__top-left, .grid__top-right {
  position: sticky;
}

.grid__top-right, .grid__bottom-right {
  text-align: right;
}

// Responsiveness

@media all and (max-width: 960px) {
  .grid {
    grid-template-columns: auto auto;
    grid-template-areas:
        "name follow"
        "nav ."
        "body body"
        "copyright archive";
  }

  .grid__top-center {
    margin: 48px 0 0 12px;
  }
  
  .grid__body {
    padding: 48px 12px;
  }

  .grid__top-left, .grid__top-right {
    position: relative;
  }
}

// Typography
.grid__top-left, .grid__top-right, .grid__bottom-left, .grid__bottom-right {
  font-family: var(--font-family-headline);
  text-transform: uppercase;
}