pre {
    padding: 30px;
    background: #f5f5f5;
    border-radius: 12px;
    overflow-x: auto;
    max-width: 100%;
}

code {
    font-size: 15px;
}