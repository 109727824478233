.avatar {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  overflow: hidden;
  margin: 0 0 30px;
}

.avatar img {
  width: 100%;
}