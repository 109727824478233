.gallery {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-width: 0;
  column-gap: 12px;
  row-gap: 12px;
}

@media all and (max-width: 960px) {
  .gallery--block {
    display: block;
  }
}