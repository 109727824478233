nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

nav a {
  color: inherit;
}

nav a:hover {
  text-decoration: underline;
  text-decoration-color: red;
}

nav a.active {
  color: red;
  text-decoration: none;
  pointer-events: none;
}