.post-list {
  list-style-type: none;
  padding: 0;
}

.post-preview {
  font-family: var(--font-family-headline);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 0.8em;
    display: inline-block;
    width: 100px;
  }
}