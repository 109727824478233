:root {
  --background-color: #FFFFFF;
  --background-darker: #FFFFFF;
  --foreground-color: #011b33;
  --glow-color: rgba(198, 236, 174, 0.3);
  --glow-color-edge: rgba(255,255,255,1);
}

@media (prefers-color-scheme: dark) {
  // :root {
  //   --background-color: #140002;
  //   --background-darker: #070101;
  //   --foreground-color: #DFD2B9;
  //   --glow-color-center: rgba(0,0,0,0);
  //   --glow-color-edge: rgba(0,0,0,0);
  // }
}