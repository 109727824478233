* {
    box-sizing: border-box;
}

html {
    background: var(--background-color);
    color: var(--foreground-color);
    height: 100%;
}

body {
    height: 100%;
}

article, section {
    margin-bottom: 60px;
}

figure {
    padding: 0;
    margin: 0 0 40px;
}

@media all and (max-width: 600px) {
    body {
        height: auto;
    }
    article, section {
        margin-bottom: 40px;
    }
    figure {
        margin-bottom: 24px;
    }
}