.playlist {
  display: flex;
  position: relative;
  margin: 0 0 20px;
}

.playlist a {
  text-decoration: none;
}

.playlist__thumb {
  position: relative;
  margin-bottom: 8px;
  transition: transform .3s;
  margin-right: 20px;
}

.playlist__image {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.playlist__source {
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.playlist__body {
  flex: 1;
  margin-bottom: 10px;
}

.playlist__name {
  font-family: var(--font-family-headline);
}