.project-list {
  font-family: var(--font-family-headline);
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    display: grid;
    grid-template-columns: 100px auto;
    @media screen and (max-width: 720px) {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
}

.project {
  margin-bottom: 16px;
  display: flex;

  &__details {
    flex: 1;
  }
  
  &__about {
    font-family: var(--font-family-copy);
  }

  &__image {
    width: 100%;
    margin: 12px 0;
    display: block;
    position: relative;
    border-radius: 16px;
    transition: transform 0.2s ease-out;
    box-shadow: 0 -1px 1px rgba(255,255,255,0.3);    

    img {
      border-radius: 16px;
    }
  }

  &__label {
    width: 100px;
    text-align: right;
  }
}

.project-list, .project {
  &__label {
    font-size: 0.8em;
    font-weight: 500;
    line-height: 2em;
    text-transform: uppercase;
  }
}
